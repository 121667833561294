/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.centered-input {
  text-align: center;
  margin-bottom: 20px;
}

button {
  margin-top: 10px;
}

.min-h-screen {
  min-height: 100vh;
}

.flex-grow {
  flex-grow: 1;
}

